.configuration-container {
  //padding: 20px;
  justify-content: flex-start !important;
  margin-left: 0 !important;
}

.configuration-content {
  margin-top: 20px;
}

h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.button-group {
  margin-top: 20px;
  display: flex;
  gap: 15px;
}
