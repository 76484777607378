.editorClassName {
    height: 150px !important;
    border: 1px solid #f1f1f1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

/* override default margin (16px / 1em) in textarea */
.public-DraftStyleDefault-block {
    margin: 0;
}
