.search__input {
    width: 50%;
    height: 60%;
    border: 1px solid #c2cad8;
    outline: none;
    border-radius: 4px;
    padding-left: 10px;
}

.MuiPagination-ul {
    justify-content: center;
    margin-top: 20px !important;
}

.item__details__container {
    width: 100%;
}

.item__detail__tables {
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3%;
}

.item__detail__table {
    width: 33.333%;
    height: 100%;
}

.tableCell {
    width: 80% !important;
    display: flex !important;
    flex-direction: row;
    border: 1px solid #c4c4c474;
}
.tableCell > h3 {
    margin: 0 !important;
    margin-left: 10px !important;
    font-weight: bold !important;
    text-align: right;
}
.tableCell > h4 {
    margin: 0 !important;
    margin-left: 10px !important;
    font-weight: bold !important;
    text-align: right;
}

.tableCell > p {
    margin: 0 !important;
    font-size: 16px !important;
}

.item__device__tables {
    width: 100%;
    background-color: #e9ecf0;
    z-index: 100;
    padding: 0 !important;
}
.item__device__cell__head > h3 {
    color: #1f2020;
    margin: 0;
}
.item__device__cell__head > h4 {
    color: #1f2020;
    margin: 0;
}
.item__details__cell__body {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    width: 20%;
    padding: 5px !important;
    font-size: small !important;
}
.item__details__cell > h3 {
    color: #1f2020 !important;
    margin: 0;
}
.item__details__cell > h4 {
    color: #1f2020 !important;
    margin: 0;
}

.item__device__cell__body > p {
    font-size: 16px;
    font-weight: 500;
}
.input {
    border-left: 1px solid #c1cad8;
    border-right: 1px solid #c1cad8;
    border-top: 1px solid #c1cad8;
    border-radius: 2px;
    padding-left: 10px;
    outline: none;
}

.search__input {
    width: 100px;
    height: 30px;
}

.tableRow {
    padding: 0 !important;
}

.tableRowCellSV {
    margin-bottom: 0 !important;
}

.tableRowFalse {
    background-color: #f9cccc;
}
