.container__picker {
    width: 100%;
    justify-content: space-between !important;
}
.container__picker .MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.container__picker .MuiInputBase-input {
    padding-left: 5px !important;
    padding-right: 5px !important;
}
.container__picker__to {
    padding-top: 8px;
}
.MuiPickersDay-daySelected,
.MuiPickerDTTabs-tabs,
.MuiPickersToolbar-toolbar {
    background-color: rgb(215, 18, 60) !important;
}
