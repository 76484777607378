.ts__item__details__container {
    width: 100%;
    height: 100vh;
}

.ts__item__detail__tables {
    width: 100%;
    background-color: white;
    justify-content: flex-start;
    flex-direction: column;
}

.ts__item__row {
    width: 100%;
    height: 20%;
    margin: 10px 0;
    align-items: center;
}

.ts__item__elements {
    width: 26%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}
.ts__item__elements > button {
    margin-left: 10px;
    box-sizing: content-box;
}

.ts__item__elements > p {
    margin-right: 10px;
}

.ts__item__elements__buttons {
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}
.ts__item__elements__buttons > button {
    margin-left: 20px;
}

.ts__item__detail__table {
    width: 33.333%;
    height: 100%;
    flex-direction: column;
}
.ts__row__container {
    width: 100%;
    height: 100%;
    border: 1px solid black;
}

#ts__input {
    padding: 10px !important;
}

.tsc__item__details__container {
    width: 100%;
    margin-bottom: 40px !important;
}

.tsc__item__row {
    width: 100%;
    height: 20%;
    padding: 20px 0;
}

.tsc__item__elements {
    width: 25%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}

.tsc__item__elements > p {
    margin-right: 10px;
}

.tsc__item__elements__buttons {
    width: 50%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}
.tsc__item__elements__buttons > button {
    margin-left: 20px;
}

.tsc__item__detail__table {
    width: 33.333%;
    height: 100%;
    flex-direction: column;
}
.tsc__row__container {
    width: 100%;
    height: 100%;
    border: 1px solid black;
}

#tsc__input {
    padding: 10px !important;
}

/* Sections */

.bool__section {
    width: 100%;
    border: 3px solid #e2325a;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
}

.string__section {
    width: 100%;
    height: 30vh;
    background-color: red;
}

.int__section {
    width: 100%;
    height: 30vh;
    background-color: yellow;
}

.float__section {
    width: 100%;
    height: 30vh;
    background-color: green;
}

.tableRow {
    padding: 0 !important;
}

.tableRowCellSV {
    margin-bottom: 0 !important;
}
