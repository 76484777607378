.item__details__tables__container {
    border: 1px solid rebeccapurple;
}

.nwu__item__details__container {
    width: 100%;
}
.item__details__container__nwu {
    margin-top: 20px;
    margin-bottom: 20px;
}

.nwu__item__detail__tables {
    width: 100%;
    background-color: #f9f9f9;
    justify-content: flex-start;
    flex-direction: column;
}

.nwu__item__row {
    width: 100%;
    height: 20%;
    padding: 10px 0;
}

.nwu__item__elements {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}
.nwu__left {
    width: 30%;
}
.nwu__right {
    width: 70%;
}

.nwu__leftrole {
    width: 50%;
}
.nwu__rightrole {
    width: 50%;
}

.nwu__item__elements > p {
    margin-right: 10px;
}

.nwu__item__elements__buttons {
    width: 50%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}
.nwu__item__elements__buttons > button {
    margin-left: 20px;
}

.nwu__item__detail__table {
    width: 33.333%;
    height: 100%;
    flex-direction: column;
}
.nwu__row__container {
    width: 100%;
    height: 100%;
    border: 1px solid black;
}

.MuiInputBase-input {
    padding: 10px !important;
}

.tableCell {
    flex-direction: row;
    border: 1px solid #c4c4c474;
    width: 50%;
}

.item__details__cell__sel {
    width: 10%;
    text-align: center !important;
    border-left: 1px solid #e0e0e0;
}

.item__details__cell__nwu {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
    width: 45%;
    text-align: center !important;
}
.item__details__cell__nwu__role {
    border-left: 1px solid #e0e0e0;
    display: flex !important;
}
.item__details__cell__nwu > h3 {
    color: #1f2020 !important;
    margin: 0;
}

.item__details__cell__sel > h3 {
    color: #1f2020 !important;
    margin: 0;
}

.button__message {
    width: 100%;
    justify-content: space-around;
    align-items: center;
}
.button__message__nwu {
    width: 100%;
}

.button__editUser {
    width: 48%;
    justify-content: space-between;
}
.button__editUser > button {
    width: 180px !important;
}

.manufacturer__container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
    align-items: flex-start;
}

.manufacturer__all-option {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    font-weight: bold;
}

.manufacturer__item {
    padding: 8px 12px;
    border-radius: 4px;
    background-color: white; // Default color
    cursor: pointer;
    color: grey;
    border: 1px solid grey;
    transition: background-color 0.3s ease, color 0.3s ease;
    min-width: fit-content;
    white-space: nowrap;

    &.selected {
        background-color: white;
        color: black; // Dark color for selected
    }
}


.buttons__container {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.buttons__container button {
    padding: 8px 16px;
}