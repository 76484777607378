.dashboard {
    flex-direction: column;
}

.container {
    width: 100%;
    height: 92vh;
    overflow-y: scroll;
}

.container__left {
    width: 14%;
    height: 100%;
    justify-content: center;
}

.container__right {
    width: 90%;
    height: 100%;
    margin-top: 1%;
    justify-content: flex-start;
    align-items: flex-start;
}

button {
    cursor: pointer;
}

.dashboard,
.container,
.container__left,
.container__right,
.table__container__top,
.table__container__left,
.table__container__right,
.date-range,
.DateRangePickerInput,
.container__picker,
.pagination,
.ts__item__detail__tables,
.ts__item__row,
.ts__item__elements,
.ts__item__elements__buttons,
.ts__item__detail__table,
.tsc__item__detail__tables,
.tsc__item__row,
.tsc__item__elements,
.tsc__item__elements__buttons,
.tsc__item__detail__table,
.nwu__item__detail__tables,
.nwu__item__row,
.nwu__item__elements,
.nwu__item__elements__buttons,
.nwu__item__detail__table,
.tableCell,
.button__message,
.button__message__nwu,
.button__editUser,
.title,
.container__right,
.form,
.login__page__container,
.login_page_container_logo,
.login__page__container__form,
.login__page__container__inputs,
.login__page__container__title,
.reports__row,
.item__detail__tables {
    display: flex !important;
}

.menu {
    width: 90%;
    height: 48%;
    background-color: white;
    border-radius: 5px;
    margin-top: 20px;
    padding-top: 14px;
}

.menu__button {
    width: 95%;
    height: 50px;
    background-color: white;
    border: none;
    margin-top: 4px;
    padding-left: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    color: rgb(147, 145, 146);
}

.menu__button:hover {
    background-color: #f2f6f9;
    cursor: pointer;
}

.menu__button__active {
    width: 93.5%;
    background-color: #f2f6f9;
    border-left: 4px solid rgb(147, 145, 146);
    color: rgb(0, 0, 0);
}
.menu__button__active:hover {
    background-color: #f2f6f9;
    cursor: pointer;
}
.icon {
    margin-right: 10px;
    align-self: center;
    color: rgb(215, 18, 60);
}
.container__right {
    width: 82%;
    height: 100%;
    display: flex;
    margin-top: 1%;
    justify-content: flex-start;
    align-items: flex-start;
}

.dashboard__component {
    width: 99%;
    height: 95%;
    background-color: white;
    border-radius: 5px;
}

.MuiButton-containedPrimary {
    background-color: #dd1a40 !important;
    color: white !important;
    border: none !important;
}
.MuiInput-underline:after {
    border-bottom: 2px solid rgb(215, 18, 60) !important;
}
.MuiInput-underline:hover:before {
    border-bottom: 2px solid rgba(0, 0, 0, 0.42) !important;
}
.table__container {
    width: 100%;
    max-height: 90%;
    height: 90%;
}
.table__container__top {
    width: 100%;
    height: 7vh;
    background-color: white;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #eef1f5;
}

.table__container__left {
    width: 50%;
    height: 100%;
    align-items: center;
}
.table__container__left > h3 {
    color: rgb(147, 145, 146);
    font-size: 22px;
    font-weight: 500;
}

.table__container__right {
    width: 50%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
}

.MuiTableCell-root {
    padding: 10px !important;
}
