// HomePage.scss

.verify-email-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 16px;
  align-items: center; /* Centers items horizontally */
}

.modal-content h2 {
  font-size: 20px;
  font-family: Arial;
  font-weight: 700;
  color: #333;
}

.modal-content h1 {
  font-size: 20px;
  font-family: Arial;
  font-weight: 400;
  color: #333;
}

.modal-content .MuiButton-containedPrimary {
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  text-transform: none;
  &:hover {
    background-color: #005bb5; // Darker shade for hover state
  }
}
