.MuiMenu-paper {
    margin-top: 6px !important;  /* Add margin to ensure it appears below */
    border: 2px solid #e8ecf2;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.header__container {
    width: 100%;
    min-height: 5vh;
    background-color: white;
    border-bottom: 2px solid #e8ecf2;
    display: flex;
    position: sticky;
    align-items: flex-end;
    top: 0;
}
.header__container__left,
.header__container__right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}
.header__container__right {
    justify-content: flex-end;
    padding-right: 20px;
}

#user__session {
    font-size: 16px;
    font-weight: 600;
    color: rgb(147, 145, 146);
    text-transform: none;
    padding-bottom: 2px;
}
.MuiMenu-list {
    border: 2px solid #e8ecf2;
}
.button__header {
    font-size: 14px;
    font-weight: 500;
    margin: 3%;
}
.header_logo {
    width: 110px;
    height: auto;
    margin: 20px;
    margin-bottom: 10px;
}

