.container__report {
    width: 100%;
}
.container__data {
    width: 80%;
}

.row__reports {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    background-color: #fff !important;
}
.row__reports__left {
    width: 40%;
    height: 100%;
    justify-content: flex-end;
    //   border: 1px solid red;
}
.row__reports__right {
    width: 70%;
    display: flex;
    align-items: center;
    //   border: 0.2px solid green;
}
.report__input {
    width: 100%;
    margin: 0 !important;
}
.row__button {
    width: 80%;
    margin: 40px 0;
    display: flex;
    // justify-content: space-around;
}
.row__button > button {
    margin-right: 10px;
}

.container__parameters {
    display: flex;
}

.container__parameter {
    width: 50%;
}
